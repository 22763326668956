import React, {
  useState, useContext, useRef, useCallback,
} from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { UserContext } from 'contexts/userContext';
import Layout from 'components/partials/Layout';
import { getSampleMachinery, getSoldMachinery, getInEventMachinery } from 'models/machinery';
import { getHomeSlider, getHomeCounters } from 'models/contents';
import Banner from './components/Banner';
import Auctions from './components/buyer/Auctions';
import InEventMachinery from './components/InEventMachinery';
import useHomeFaqContent from './hooks/useHomeFaqContent';

const VideoModal = dynamic(() => import('components/modals/VideoModal'));
const VideoAndDetail = dynamic(() => import('./components/seller/VideoAndDetail'));
const ContactInformation = dynamic(() => import('./components/seller/ContactInformation'));
const SoldMachinery = dynamic(() => import('./components/SoldMachinery'));
const Detail = dynamic(() => import('./components/buyer/Detail'));
const HowIsWork = dynamic(() => import('./components/howIsWork'));
const WhyComponent = dynamic(() => import('./components/whyComponent'));
const AreYouSeller = dynamic(() => import('./components/seller/AreYouSeller'));
const AreYouBuyer = dynamic(() => import('./components/buyer/AreYouBuyer'));
const FooterImage = dynamic(() => import('./components/FooterImage'));
const CustomersSays = dynamic(() => import('./components/customersSays/CustomersSays'));
const FrequentlyAskedQuestions = dynamic(() => import('./components/FrequentlyAskedQuestions'));
const Counter = dynamic(() => import('./components/Counter'));

const Home = ({
  machines, sliders, soldMachines, inEventMachines, counters,
}) => {
  const { userData } = useContext(UserContext);
  const { isSeller } = userData;
  const { homeFaqBuyer, homeFaqSeller } = useHomeFaqContent();

  const [videoUrl, setVideoUrl] = useState('');

  const orderCounter = useRef(0);

  const getNextClassName = useCallback(() => {
    const className = orderCounter.current % 2 === 0 ? 'section section--transparent section--shadow' : 'section';
    orderCounter.current += 1;
    return className;
  }, []);

  return (
    <>
      <main>
        <Banner
          setVideoUrl={setVideoUrl}
          sliders={sliders}
        />

        {
          !_.isEmpty(inEventMachines)
          && (
            <InEventMachinery machines={inEventMachines} setVideoUrl={setVideoUrl} classNameForBG={getNextClassName()} />
          )
        }
        <Detail classNameForBG={getNextClassName()} />

        {
          !_.isEmpty(machines) && (
            <>
              <Auctions
                machines={machines}
                classNameForBG={getNextClassName()}
              />
            </>
          )
        }

        <HowIsWork classNameForBG={getNextClassName()} />
        <WhyComponent classNameForBG={getNextClassName()} />

        {
          isSeller
          && (
            <>
              <VideoAndDetail
                setVideoUrl={setVideoUrl}
                classNameForBG={getNextClassName()}
              />
            </>
          )
        }

        <Counter
          data={counters}
          classNameForBG={getNextClassName()}
        />

        <CustomersSays
          classNameForBG={getNextClassName()}
        />

        {
          !_.isEmpty(soldMachines)
          && (
            <SoldMachinery
              machines={soldMachines}
              classNameForBG={getNextClassName()}
            />
          )
        }

        <FrequentlyAskedQuestions
          questions={isSeller ? homeFaqSeller : homeFaqBuyer}
          classNameForBG={getNextClassName()}
        />

        {
          isSeller
            ? (
              <AreYouBuyer
                classNameForBG={getNextClassName()}
              />
            )
            : (
              <AreYouSeller
                classNameForBG={getNextClassName()}
              />
            )
        }

        <ContactInformation
          classNameForBG={getNextClassName()}
        />

        <FooterImage />

      </main>
      <VideoModal
        videoUrl={videoUrl}
        setVideoUrl={setVideoUrl}
      />
    </>
  );
};

/* Home.getInitialProps = async () => ({
  namespacesRequired: ['common'],
}); */

Home.propTypes = {
  machines: PropTypes.array,
  soldMachines: PropTypes.array,
  sliders: PropTypes.array,
  inEventMachines: PropTypes.array,
  counters: PropTypes.object,
};

export const getServerSideProps = async () => {
  try {
    const { results: machines } = await getSampleMachinery(3);
    const { results: soldMachines } = await getSoldMachinery();
    const { results: inEventMachines } = await getInEventMachinery();
    const { sliders } = await getHomeSlider();

    const homepageCounters = await getHomeCounters();
    return {
      props: {
        machines: machines || [],
        sliders: sliders || [],
        soldMachines: soldMachines || [],
        inEventMachines: inEventMachines || [],
        counters: homepageCounters || {},
      },
    };
  } catch (error) {
    return {
      props: {
        machines: [],
        slider: [],
        soldMachines: [],
        inEventMachines: [],
        counters: {},
      },
    };
  }
};

Home.Layout = Layout;

export default Home;
