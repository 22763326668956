import _ from 'lodash';

export const getObjectByLanguage = (data) => {
  // const currentLanguage = i18n.language;
  const currentLanguage = 'tr';
  const value = _.result(data, `${currentLanguage}`);

  if (!_.isUndefined(value) && data && data.tr) {
    return data.tr;
  }
  if (data) {
    return data;
  }
  return '';
};
