/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';

import maskAmount from 'utils/maskAmount';
import { withTranslation } from 'language/i18n';
import { getObjectByLanguage } from 'language/helpers';
import types from 'constants/types';
import { MACHINE_STATE } from 'constants/common';

const AuctionItem = ({ t, machine, key }) => (
  <div className="col-15 col-md-7 col-lg-5 mb-30" key={key}>
    <Link href="/machinery/[id]" as={`/machinery/${machine._id}`}>
      <a className="im-product-card">
        <div className="mb-20 product-card-image">
          <Image
            src={machine.images.length > 0 ? machine.images[0].thumbnail : '/image/machine-detail-slider-placeholder.jpg'}
            width="340"
            height="255"
            layout="responsive"
          />
          {machine.saleState === MACHINE_STATE.SOLD && (
            <div className="image-sold-text-container">
              <div className="image-sold-text">SATILDI</div>
            </div>
          )}
          {machine.saleState === MACHINE_STATE.SUSPENDED && (
            <div className="image-sold-text-container">
              <div className="image-sold-text" style={{ transform: 'rotate(-25deg) scale(0.6)' }}>ÖDEME BEKLENİYOR</div>
            </div>
          )}
          {machine.saleState === MACHINE_STATE.IN_EVENT && (
            <div className="image-sold-text-container">
              <div className="image-sold-text">SATIŞTA</div>
            </div>
          )}
        </div>
        <div className="im-product-card__title">
          <span>{getObjectByLanguage(machine.title)}</span>
        </div>
        <div className="im-product-card__props">
          <div className="row">
            <div className="col-6">
              <div className="im-product-card__props__title">
                {t('machineNo')}
              </div>
            </div>
            <div className="col pl-0">
              <div className="im-product-card__props__value">
                {machine.machineId}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="im-product-card__props__title">
                {t('locationDif')}
              </div>
            </div>
            <div className="col pl-0">
              <div className="im-product-card__props__value">
                {`${getObjectByLanguage(machine.location.region)}, ${getObjectByLanguage(machine.location.country)}`}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="im-product-card__props__title">
                {t('saleType')}
              </div>
            </div>
            <div className="col pl-0">
              <div className="im-product-card__props__value">{types[machine.buyingFormat]}</div>
            </div>
          </div>
        </div>
        <div className="im-product-card__price">
          <div className="im-product-card__price__value">{maskAmount(machine.startingPrice)}</div>
          <div className="im-product-card__price__title">{t('startingPrice')}</div>
        </div>
      </a>
    </Link>
  </div>
);

AuctionItem.propTypes = {
  machine: PropTypes.object,
  t: PropTypes.func,
  key: PropTypes.string,
};

export default withTranslation('common')(AuctionItem);
