/* eslint-disable no-underscore-dangle */
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withTranslation } from 'language/i18n';
import AuctionItem from './buyer/AuctionItem';

const InEventMachinery = ({
  t, machines, setVideoUrl, classNameForBG,
}) => (
  <section className={classNameForBG}>
    <div className="container">
      <div className="row">
        <div className="col">
          <h2 className="section__header__title section__header__title--mb-45 text-center">{t('startedAuctions')}</h2>
        </div>
      </div>
      <div className="row row--mb30">
        <div className="col-15">
          <div className="row justify-content-center">
            {
              _.map(machines, (machine) => (
                <AuctionItem machine={machine} key={machine._id} />
              ))
            }

          </div>
          <div className="row mt-3">
            <div className="col-15">
              <div className="im-box im-box--transparent im-box--padding-15 im-box--border">
                <div className="row align-items-center">
                  <div className="col-15 col-sm-10 pl-sm-30">
                    <h3 className="h3">{t('howToBid')}</h3>
                    <p className="fs-20 fw-300 mb-30">{t('howToBidExplanation')}</p>
                    <Link href="/content/buying/before-bidding">
                      <button type="button" className="im-button im-button--primary">
                        <span>{t('findOutHowToBid')}</span>
                        <div className="far fa-long-arrow-right im-button__icon--right" />
                      </button>
                    </Link>
                  </div>
                  <div className="col-15 col-sm-5 mt-4 mt-sm-0">
                    <div className="media-container media-container--smaller media-container--no-shadow media-container--bg-white">
                      <Image
                        src="/image/buyer_thumbnail.jpeg"
                        layout="fill"
                      />
                      <a
                        className="im-button im-button--transparent media-container__button-play"
                        onClick={() => setVideoUrl('https://www.youtube.com/embed/XrMdGdKiP6w?autoplay=1&amp;autohide=1&amp;fs=1&amp;rel=0&amp;hd=1&amp;wmode=transparent&amp;enablejsapi=1&amp;html5=1')}
                      >
                        <svg>
                          <use xlinkHref="./image/icons.svg#ic-play-yellow" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <Link href="/machinery">
          <a className="im-button im-button--primary">Satıştaki Makinaları İncele</a>
        </Link>
      </div>
    </div>
  </section>
);

InEventMachinery.propTypes = {
  machines: PropTypes.array,
  setVideoUrl: PropTypes.func,
  t: PropTypes.func,
  classNameForBG: PropTypes.string,
};

export default withTranslation('common')(InEventMachinery);
